<template>
  <div>
    <!-- <gl-card>
      <rows-card></rows-card>
    </gl-card> -->
    <gl-card>
      <chart-top 
        title="酷喜数量" 
        @click-change="onTimeChange('coolCount', $event)" 
        :type.sync="coolCountType">
      </chart-top>
      <homeChart :chartData="coolCountData" :type="coolCountType"/>
    </gl-card>
    <gl-card>
      <chart-top 
        title="酷喜销量" 
        @click-change="onTimeChange('coolSales', $event)" 
        :type.sync="coolSalesType">
      </chart-top>
      <homeChart :chartData="coolSalesData" :type="coolSalesType"/>
    </gl-card>
    <gl-card>
      <chart-top 
        title="商品销量" 
        @click-change="onTimeChange('goodsSales', $event)" 
        :type.sync="goodsSalesType">
      </chart-top>
      <homeChart :chartData="goodsSalesData" :type="goodsSalesType"/>
    </gl-card>
  </div>
</template>

<script>
import chartTop from '../../../components/chart-top.vue'
import homeChart from '../../../components/home-chart.vue'
import rowsCard from '../../../components/rows-card.vue'
import cardTitle from '../../../components/card-title.vue'
export default {
  components: {
    chartTop,
    homeChart,
    rowsCard,
    cardTitle,
  },
  data(){
    return{
      coolCountData: {},
      coolCountType: 'line',
      coolSalesData: {},
      coolSalesType: 'line',
      goodsSalesData: {},
      goodsSalesType: 'line',
      funAndForm: {
        'coolCount': {   // 搭档数量 
          form: {
            days: '1'
          },
          func: this.loadCoolCountData
        },
        'coolSales': { // 搭档销量排行
          form: {
            days: '1'
          },
          func: this.loadCoolSalesData
        },
        'goodsSales': { // 商品销量
          form: {
            days: '1',
          },
          func: this.loadGoodsSalesData
        },
      },
    }
  },
  mounted(){
    // Object.keys(this.funAndForm).forEach(key => {
    //   const loadList = this.funAndForm[key].func
    //   loadList && loadList()
    // })
  },
  methods: {
    loadCoolCountData(){
      const params = this.funAndForm['coolCount'].form
      this.coolCountData = {
        name: '酷喜数量',
        type: 'line',
        xData: ['2022-05','2022-06','2022-07','2022-08','2022-09','2022-10','2022-11'],
        yData: [12,43,35,4,1,16,88],
        series: []
      }
    },
    loadCoolSalesData(){
      const params = this.funAndForm['coolSales'].form
      this.coolSalesData = {
        name: '搭档销量',
        type: 'line',
        xData: ['2022-05','2022-06','2022-07','2022-08','2022-09','2022-10','2022-11'],
        yData: [12,43,35,4,100,50,88],
        series: []
      }
    },
    loadGoodsSalesData(){
      const params = this.funAndForm['goodsSales'].form
      this.goodsSalesData = {
        name: '商品销量',
        type: 'line',
        xData: ['2022-05','2022-06','2022-07','2022-08','2022-09','2022-10','2022-11'],
        yData: [12,43,35,4,100,138,88],
        series: []
      }
    },
    onTimeChange(chartType, item){

      return

      const {type, startTime, endTime, dateTime} = item
      const loadList = this.funAndForm[chartType].func
      
      this.funAndForm[chartType].form.days = type
    
      if(type == 'custom'){
        if(!startTime && !endTime) return
        console.error('自定义选项时间:', dateTime)
        this.funAndForm[chartType].form.startTime = startTime
        this.funAndForm[chartType].form.endTime = endTime
      }else{
        this.funAndForm[chartType].form['startTime'] && delete this.funAndForm[chartType].form['startTime']
        this.funAndForm[chartType].form['endTime'] && delete this.funAndForm[chartType].form['endTime']
      } 
      
      loadList && loadList()
    },
  },
}
</script>

<style>

</style>